<template>
  <div id="app">
    <web-document-viewer v-if="$route.query.mode!=='design'" />
    <ReportDesignerComponent v-else />
  </div>
</template>

<script>
import WebDocumentViewer from "./components/WebDocumentViewer";
import ReportDesignerComponent from './components/ReportDesignerComponent';
export default {
  name: 'App',
  components: {
    WebDocumentViewer,
    ReportDesignerComponent
  }
}
</script>
