<template>
  <div ref="designer" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0" data-bind="dxReportDesigner: $data"></div>
</template>

<script>
import ko from "knockout";
import "devexpress-reporting/dx-reportdesigner";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";

export default {
  name: "ReportDesignerComponent",
  mounted() {
    window.console.log(this.$route.query.reportHost)
    const designerOptions = {
      reportUrl: ko.observable(this.$route.query.reportName),
      requestOptions: {
        host: this.$route.query.reportHost,
        getDesignerModelAction: "DXXRD/GetDesignerModel",
      }
    };
    ajaxSetup.ajaxSettings = {
      // xhrFields: {
      //   withCredentials: true
      // },
      headers: {
        // 'X-XSRF-TOKEN': this.$route.query.csrf,
        Authorization: this.$route.query.token,
        Accept: 'application/json',
        contentType: 'application/json',
        NBOData: this.$route.query.reportApi
      }
    }
    ko.applyBindings(designerOptions, this.$refs.designer);
  },
  beforeUnmount() {
    ko.cleanNode(this.$refs.designer);
  }
};
</script>
