<template>
  <div ref="viewer" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0"></div>
</template>
<script>
import 'devexpress-reporting/dx-webdocumentviewer';
import {
  DxReportViewer
} from 'devexpress-reporting/dx-webdocumentviewer';
import * as ko from 'knockout';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'

export default {
  name: "WebDocumentViewer",
  mounted() {
    const reportUrl = ko.observable(this.$route.query.reportName);
    const requestOptions = {
      host: this.$route.query.reportHost,
      invokeAction: "DXXRDV"
    }
    ajaxSetup.ajaxSettings = {
      // xhrFields: {
      //   withCredentials: true
      // },
      headers: {
        // 'X-XSRF-TOKEN': this.$route.query.csrf,
        Authorization: this.$route.query.token,
        Accept: 'application/json',
        contentType: 'application/json',
        NBOData: this.$route.query.reportApi
      }
    }
    const viewer = new DxReportViewer(this.$refs.viewer, {
      reportUrl,
      requestOptions
    });
    viewer.render();
  },
  beforeUnmount() {
    ko.cleanNode(this.$refs.viewer);
  }
};
</script>
